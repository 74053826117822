import React from "react"
import styled from "styled-components"
import downloadIcon from "../../../assets/icons/download-icon.svg"

export const BlogSocialShareRow = ({
  slug,
  backgroundColor,
  iconColor,
  className,
  seo,
  downloadUrl,
  isGated,
}) => {
  const pathName =
    typeof window !== "undefined"
      ? encodeURIComponent(window.location.host)
      : ""
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${pathName}/blog/${slug}&text=${seo?.seoDescription}&via=Workgrid`
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pathName}/blog/${slug}&title=${seo?.seoTitle}`
  const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${pathName}/blog/${slug}`

  const socialIcons = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-grey-circ.svg",
      link: twitterShareUrl,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/fb-grey-circ.svg",
      link: facebookShareUrl,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-grey-circ.svg",
      link: linkedinShareUrl,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/insta-grey-circ.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]
  const socialIconsBlue = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-blue.svg",
      link: twitterShareUrl,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/facebook-blue.svg",
      link: facebookShareUrl,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-blue.svg",
      link: linkedinShareUrl,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/instagram-blue.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]

  // //replace linkedin with before prod///
  // const pathName = typeof window !== 'undefined' ? encodeURIComponent(window.location.host) : '';
  // https://www.linkedin.com/shareArticle?mini=true&url=${pathName}/blog/${slug}

  const handleSocialShare = url => {
    // eslint-disable-next-line
    const left = (screen.width - 570) / 2
    // eslint-disable-next-line
    const top = (screen.height - 570) / 2
    const params =
      "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
      top +
      ",left=" +
      left
    window.open(url, "NewWindow", params)
  }

  return (
    <CompWrapper
      data-comp={BlogSocialShareRow?.displayName}
      style={{ backgroundColor: `${backgroundColor}` }}
      className={className}
    >
      <SocialShareWrapper>
        <Title>SHARE</Title>
        <IconFlex>
          {!iconColor
            ? socialIcons.map((icon, idx) => (
                <>
                  <div
                    onClick={() => handleSocialShare(icon?.link)}
                    onKeydown={() => handleSocialShare(icon?.link)}
                    role="button"
                    tabIndex={0}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={idx}
                  >
                    <IconWrapper>
                      <img
                        src={icon.iconPath}
                        alt={`${icon.iconName} share button`}
                      />
                    </IconWrapper>
                  </div>
                </>
              ))
            : socialIconsBlue.map((icon, idx) => (
                <>
                  <div
                    onClick={() => handleSocialShare(icon?.link)}
                    onKeydown={() => handleSocialShare(icon?.link)}
                    role="button"
                    tabIndex={0}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={idx}
                  >
                    <IconWrapper>
                      <img
                        src={icon.iconPath}
                        alt={`${icon?.iconName} share button`}
                      />
                    </IconWrapper>
                  </div>
                </>
              ))}
        </IconFlex>
      </SocialShareWrapper>
      {downloadUrl && !isGated && (
        <DownloadWrap>
          <DownloadTitle>DOWNLOAD</DownloadTitle>
          <a
            href={downloadUrl}
            target="_blank"
            rel="noreferrer noopener"
            download
          >
            <IconWrapper>
              <img src={downloadIcon} alt="download asset" />
            </IconWrapper>
          </a>
        </DownloadWrap>
      )}
    </CompWrapper>
  )
}

BlogSocialShareRow.displayName = "BlogSocialShareRow"

const CompWrapper = styled.div`
  margin: 24px auto 0;
  border-radius: 6px;
  padding: 0 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const Title = styled.div`
  padding: 32px 0 16px 0;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #475467;
`

const IconFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 43px;
  padding-bottom: 32px;
  @media (max-width: 576px) {
    gap: 29px;
  } ;
`

const IconWrapper = styled.div`
  cursor: pointer;
  img {
    transition: filter 200ms ease;
  }
  img:hover {
    opacity: 0.75;
  }
`

const SocialShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DownloadTitle = styled.div`
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #475467;
`

const DownloadWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`

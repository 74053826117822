import React, { useState } from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import CardDeck from "@components/CardDeck/CardDeck"
import ConversionPanelStandard from "@components/ConversionPanel/ConversionPanelStandard"
import {
  WebinarHero,
  BreadCrumbFlex,
  IconImg,
  WebinarHeading,
} from "./styles/webinarDetail.styled"
import WebinarContent from "../components/Resources/WebinarContent"
import OptimizedImage from "../utils/optimizedImage"

const WebinarDetailTemplate = ({ data }) => {
  const webinarData = data?.contentfulResource
  const conversionPanel = data?.contentfulComponentConversionPanel
  const cardDeck = data?.contentfulComponentCardDeck
  const cardDeckPosts = webinarData?.relatedPosts
  const video = webinarData?.videoResource
  const speakers = webinarData?.speakers
  const speakersBody = webinarData?.speakersBody?.speakersBody
  const form = webinarData?.form
  const seo = webinarData?.pageSeo
  const heroImage = data?.contentfulComponentImage

  const [submitted, setSubmitted] = useState(false)

  const breadStyle = {
    fontWeight: 400,
    textDecoration: "none",
    color: "white",
    transition: "color 200ms ease",
  }

  return (
    <Layout headerTheme={{ theme: "dark", background: "transparent" }}>
      <Seo
        title={seo?.seoTitle || webinarData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || heroImage?.primaryImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <WebinarHero>
        <OptimizedImage
          className="hero-image"
          loading="eager"
          image={heroImage?.primaryImage?.gatsbyImageData}
          src={heroImage?.primaryImage?.file?.url}
          alt="hero-image"
        />
        <BreadCrumbFlex>
          <Link href="/">
            <span style={breadStyle}>Home</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p>Resources</p>
            </span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p className="bc-big">Webinar</p>
            </span>
          </Link>
        </BreadCrumbFlex>
        <WebinarHeading>
          {webinarData?.category && (
            <p className="eyebrow">{webinarData?.category[0]?.title}</p>
          )}
          {webinarData?.title && <p className="title">{webinarData?.title}</p>}
        </WebinarHeading>
      </WebinarHero>
      <WebinarContent
        submitted={submitted}
        setSubmitted={setSubmitted}
        webinarData={webinarData}
        video={video}
        speakers={speakers}
        speakersBody={speakersBody}
        form={form}
      />
      <CardDeck
        backgroundColor={"white"}
        heading={cardDeck?.heading}
        subheading={cardDeck?.subheading?.subheading}
        headingSize={cardDeck?.headingSize}
        kicker={cardDeck?.kicker}
        cards={cardDeckPosts}
      />
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export default WebinarDetailTemplate

export const WebinarTemplateQuery = graphql`
  query WebinarTemplateQuery($id: String) {
    contentfulResource(id: { eq: $id }) {
      id
      title
      slug
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
      }
      publishDate
      externalUrl
      category {
        title
        backgroundColor
      }
      featuredImage {
        gatsbyImageData
        file {
          url
        }
      }
      form {
        marketoFormId
        background
      }
      description {
        description
      }
      body {
        raw
      }
      videoResource {
        id
        caption {
          raw
        }
        alignCaption
        contentful_id
        autoplay
        title
        videoUrl
        thumbnail {
          gatsbyImageData
          file {
            url
          }
        }
        videoFile {
          title
          description
          gatsbyImageData
          file {
            url
          }
        }
        internal {
          type
        }
      }
      speakers {
        fullName
        headshot {
          gatsbyImageData
          url
        }
        role
        company {
          name
        }
      }
      speakersBody {
        speakersBody
      }
      relatedPosts {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          form {
            marketoFormId
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "e4b8aa82-a897-521b-b048-14212ea7f956" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      noTopPadding
      noBottomPadding
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
    contentfulComponentCardDeck(
      id: { eq: "268d2ae2-d30e-55cf-887e-a8dc447964a1" }
    ) {
      __typename
      id
      type
      cardType
      kicker
      heading
      headingSize
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
    }
    contentfulComponentImage(
      id: { eq: "d4a17ddc-8fd9-596e-afe4-51015f3b4fce" }
    ) {
      __typename
      id
      primaryImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
    }
  }
`

const getYoutubeId = url => {
  const youtubeRegExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url?.match(youtubeRegExp)
  return match && match[1].length === 11 ? match[1] : false
}

const getVimeoId = url => {
  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_]+)?/i
  const match = url?.match(vimeoRegExp)
  return match && match[1] ? match[1] : false
}

const getCustomUrl = videoFile => {
  const videoUrl = 'https:' + videoFile?.file?.url
  return videoUrl
}

export const validatePlay = (video, setChannel, setVideo, setPlay) => {
  if (video?.videoUrl) {
    if (video?.videoUrl?.includes("youtu.be")) {
      const youtubeId = getYoutubeId(video?.videoUrl)
      setChannel("youtube")
      setVideo(youtubeId)
      setPlay(true)
    }
    if (video?.videoUrl.includes("vimeo")) {
      const vimeoId = getVimeoId(video?.videoUrl)
      setChannel("vimeo")
      setVideo(vimeoId)
      setPlay(true)
    }
  } else if (video?.videoFile) {
    const customId = getCustomUrl(video?.videoFile)
    setChannel("custom")
    setVideo(customId)
    setPlay(true)
  }
}

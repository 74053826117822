import React from "react"
import OptimizedImage from "../../utils/optimizedImage"
import { SpeakersWrapper, SpeakerContainer } from "./styles/WebinarSpeakers.styled"

const Speaker = ({ indiv }) => {
  return (
    <SpeakerContainer>
      <OptimizedImage image={indiv?.headshot?.gatsbyImageData} src={indiv?.headshot?.url} alt={indiv?.fullName || "speaker image"}/>
      <div className="content">
        <p className="heading">{indiv?.fullName}</p>
        <p>{indiv?.role}</p>
        <p>{indiv?.company?.name}</p>
      </div>
    </SpeakerContainer>
  )
}

export const WebinarSpeakers = ({ speakers, className, speakersBody }) => {
  return (
    <SpeakersWrapper className={className}>
      <p className="title">Speakers</p>
      <p className="speakers-body">{speakersBody}</p>
      {speakers.map((speaker, index) =>(
        <Speaker indiv={speaker} key={index} />
      ))}
    </SpeakersWrapper>
  )
}
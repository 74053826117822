import React from "react"

import { useState } from "react"
import ModalVideo from "react-modal-video"
import { VideoFrame } from "../../styles/components/VideoFrame"
import OptimizedImage from "../../utils/optimizedImage"
import {
  VideoSectionWrapper,
  ImageWrapper,
  ThumbnailWrapper,
} from "./styles/VideoSectionWebinar.styled"
import { validatePlay } from "../../utils/validateVideo"
import onKeyDown from "../../utils/onKeyDown"

export const VideoSectionWebinar = ({ component }) => {
  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [channel, setChannel] = useState("")

  const playVideo = featuredVideo => {
    validatePlay(featuredVideo, setChannel, setVideo, setPlay)
  }

  return (
    <>
      <VideoSectionWrapper>
        <div className="content">
          <div
            style={{
              margin: "0 auto",
              maxWidth: "1200px",
            }}
          >
            <ImageWrapper>
              <ThumbnailWrapper>
                <figure className="blog-image-wrapper">
                  {component?.thumbnail ? (
                    <div>
                      <OptimizedImage
                        image={component?.thumbnail?.gatsbyImageData}
                        src={component?.thumbnail?.file?.url}
                        alt={component?.thumbnail?.description || component?.thumbnail?.title || "thumbnail"}
                      />
                    </div>
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span
                    onClick={() => playVideo(component)}
                    role="button"
                    aria-label="play"
                    tabIndex={0}
                    onKeyDown={e =>
                      onKeyDown(e, () => playVideo(component), "Enter")
                    }
                  >
                    <img src="/icons/video-play.svg" alt="watch video button" />
                  </span>
                </div>
              </ThumbnailWrapper>
            </ImageWrapper>
          </div>
        </div>
        {typeof window !== "undefined" && (
          <VideoFrame>
            <ModalVideo
              channel={channel}
              isOpen={play}
              videoId={videoId}
              url={channel === "custom" && videoId}
              onClose={() => setPlay(false)}
              autoplay={true}
            />
          </VideoFrame>
        )}
      </VideoSectionWrapper>
    </>
  )
}

import styled from "styled-components"
import WebinarHeroImage from "../../../static/images/WebinarHeroImage.svg"

export const WebinarHero = styled.div`
  font-family: Roboto;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .hero-image {
    position: absolute;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    img {
      min-height: 630px;
      @media (max-width: 992px) {
        min-height: 400px;
      }
    }
  }
`

export const WebinarHeading = styled.div`
  position: relative;
  max-width: 770px;
  margin: 0 auto 64px;
  padding-top: 180px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    padding-top: 175px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
  
  .eyebrow {
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #98A2B3;
    margin-bottom: 16px;
  }
  .title {
    font-weight: 900;
    font-size: 50px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 16px;
    @media (max-width: 992px) {
      font-size: 38px;
      line-height: 48px;
    }
  }
`

export const BreadCrumbFlex = styled.div`
  margin-top: 100px;
  position: absolute;
  z-index: 100;
  padding: 24px 15px;
  top: 0%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1170px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  a {
    text-decoration: none;
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #DDDDDD;
    }
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
`

export const WebinarContainerGated = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  font-family: Roboto,sans-serif;
  @media (max-width: 992px) {
    max-width: 770px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
    max-width: 506px;
    grid-template-columns: auto;
  }
  .description {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #475467;
    margin-top: 32px;
  }
  .body {
    margin-top: 32px;
  }
  .mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
      width: 100%;
      h1 {
        font-size: 36px!important;
        line-height: 44px!important;
      }
    }
  }
  .desktop {
    @media (max-width: 992px) {
      display: none;
    }
  }
`

export const WebinarContainerUngated = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  font-family: Roboto,sans-serif;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const WebinarContentColumnGated = styled.div`
  grid-column: 1/3;
  position: relative;
  @media (max-width: 992px) {
    grid-column: 1;
  }
  .thumbnail-wrapper {
    position: relative;
    .img-wrapper {
      img {
        width: 100%;
        border-radius: 8px;
      }
      :after {
        content: "";
        border-radius: 8px;
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 100%;
        display: inline-block;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 49.48%,
            rgba(0, 0, 0, 0.5) 100%
          ),
          url(.jpg);
      }
    }
    .unlockicon {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`

export const WebinarContentColumnUngated = styled.div`
  position: relative;
  max-width: 770px;
  margin: 0 auto;
  .description {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #475467;
    margin-top: 32px;
  }
  .body {
    margin-top: 32px;
  }
`
  
export const WebinarFormColumn = styled.div`
  grid-column: 3; 
  min-width: 390px;
  position: relative;
  @media (max-width: 992px) {
    grid-column: 1;
    min-width: unset;
  }
`
import styled from "styled-components"

export const SpeakersWrapper = styled.div`
  background: #F2F4F7;
  padding: 24px;
  border-radius: 6px;
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #002DC2;
  }
  .heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #0A0033;
  }
  .speakers-body {
    margin: 12px 0 15px;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
  }
`
  
  export const SpeakerContainer = styled.div`
  display: flex;
  flex-direction: row;
  .gatsby-image-wrapper {
    width: 150px;
    height: 150px;
    margin: 15px 0;
    @media (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
  }
  img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    @media (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
  }
  .content {
    margin: auto 15px;
  }
`
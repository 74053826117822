import React from "react"
import MktoForm from "../Form/MktoForm"
import { postDetailResolveRichText } from "../../utils/resolve-rich-text/blogDetailResolveRichText"
import { BlogSocialShareRow } from "../Blog/BlogDetail/BlogSocialShareRow"
import { VideoSectionWebinar } from "../VideoSection/VideoSectionWebinar"
import { WebinarSpeakers } from "./WebinarSpeakers"
import {
  WebinarContainerGated,
  WebinarContainerUngated,
  WebinarContentColumnGated,
  WebinarContentColumnUngated,
  WebinarFormColumn,
} from "../../templates/styles/webinarDetail.styled"
import OptimizedImage from "../../utils/optimizedImage"

const WebinarContent = ({
  submitted,
  setSubmitted,
  webinarData,
  video,
  speakers,
  speakersBody,
  form,
}) => {
  if (!submitted) {
    return (
      <WebinarContainerGated>
        <WebinarContentColumnGated>
          {webinarData?.videoResource && (
            <div className="thumbnail-wrapper">
              <figure className="img-wrapper">
                <OptimizedImage
                  image={webinarData?.videoResource?.thumbnail?.gatsbyImageData}
                  src={webinarData?.videoResource?.thumbnail?.file?.url}
                  alt={webinarData?.videoResource?.thumbnail?.description || webinarData?.videoResource?.thumbnail?.title || "webinar thumbnail"}
                />
              </figure>
              <div className="unlockicon">
                <span>
                  <img
                    src="/icons/video-locked.svg"
                    alt="fill out form to unlock video"
                  />
                </span>
              </div>
            </div>
          )}
          {webinarData?.description && (
            <p className="description desktop">
              {webinarData?.description?.description}
            </p>
          )}
          {webinarData?.body && (
            <div>
              <p className="body desktop">
                {postDetailResolveRichText(webinarData)}
              </p>
            </div>
          )}
          {speakers && (
            <div>
              <WebinarSpeakers
                className="desktop"
                speakers={speakers}
                speakersBody={speakersBody}
              />
            </div>
          )}
        </WebinarContentColumnGated>
        <WebinarFormColumn>
          {form && (
            <MktoForm
              formId={form?.marketoFormId}
              background={form?.background}
              setSubmitted={setSubmitted}
            />
          )}
          {/* description and body below render conditionally based on screen size */}
          {webinarData?.description && (
            <p className="description mobile">
              {webinarData?.description?.description}
            </p>
          )}
          {webinarData?.body && (
            <div>
              <p className="body mobile">
                {postDetailResolveRichText(webinarData)}
              </p>
            </div>
          )}
          {speakers && (
            <div>
              <WebinarSpeakers
                className="mobile"
                speakers={speakers}
                speakersBody={speakersBody}
              />
            </div>
          )}
          <div>
            <BlogSocialShareRow
              className="dekstop"
              slug={webinarData?.slug}
              iconColor={"blue"}
              backgroundColor={"#F2F4F7"}
            />
          </div>
        </WebinarFormColumn>
      </WebinarContainerGated>
    )
  } else if (submitted) {
    return (
      <WebinarContainerUngated>
        {webinarData?.videoResource && (
          <VideoSectionWebinar component={video} />
        )}
        <WebinarContentColumnUngated>
          {webinarData?.description && (
            <p className="description">
              {webinarData?.description?.description}
            </p>
          )}
          {webinarData?.body && (
            <div>
              <p className="body">{postDetailResolveRichText(webinarData)}</p>
            </div>
          )}
          {speakers && (
            <div>
              <WebinarSpeakers
                speakers={speakers}
                speakersBody={speakersBody}
              />
            </div>
          )}
          <BlogSocialShareRow
            slug={webinarData?.slug}
            iconColor={"blue"}
            backgroundColor={"#F2F4F7"}
          />
        </WebinarContentColumnUngated>
      </WebinarContainerUngated>
    )
  }
}

export default WebinarContent
